import React from "react";
import styles from "./FinancialIndustry.module.scss";

import CalendarLottie from "@atoms/CalendarLottie";
import Author from "@molecules/Author";
import Tabs from "@organism/Tabs/Tabs";
import TabItem from "@organism/Tabs/TabItem";

import chatVideoEN from "../../../assets/video/Chat_Ingles_desk.mp4";
import chatVideoES from "../../../assets/video/Chat_esp_desk.mp4";
import chatVideoPR from "../../../assets/video/Chat_port_desk.mp4";
import MaxHarari from "../../../assets/image/Max-Harari-Invent.webp";
import Pablo from "../../../assets/image/Pablo.webp";
import Inigo from "../../../assets/image/Inigo.webp";
import Tebi from "../../../assets/image/tebi.webp";
import { useOnScreen } from "../../../hooks/useOnScreen";
import { m, LazyMotion, domAnimation } from "framer-motion";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import ServicesTabsMobile from "@organism/ServicesTabsMobile";
import DividerCenter from "@atoms/DividerCenter";

const imageVariants = {
	initial: {},
	hover: {},
};

const grayCard = {
	initial: {
		y: 0,
		x: -60,
	},
	hover: {
		x: -70,
		y: -10,
		transition: {
			duration: 0.3,
		},
	},
};

const pablo = {
	initial: {
		x: "-45%",
	},
	hover: {
		x: "-51%",
		y: -10,
		transition: {
			duration: 0.3,
		},
	},
};

const middleCard = {
	initial: {
		x: "-50%",
		y: "-50%",
	},
	hover: {
		x: "-45%",
		y: "-45%",
		transition: {
			duration: 0.3,
		},
	},
};

const bottomCard = {
	initial: {
		x: "-50%",
	},
	hover: {
		x: "-55%",
		y: 10,
		transition: {
			duration: 0.3,
		},
	},
};

const rightCard = {
	initial: {
		y: "-50%",
	},
	hover: {
		x: 10,
		y: "-51%",
		transition: {
			duration: 0.3,
		},
	},
};

function handleDesktopVideo(val) {
	switch (val) {
		case "en":
			return chatVideoEN;
		case "es":
			return chatVideoES;
		case "pr":
			return chatVideoPR;
		default:
			return chatVideoEN;
	}
}

const FinancialIndustry = ({ openChat, language }) => {
	const { t } = useTranslation();
	const breakpoints = useBreakpoint();
	// const { language } = useI18next();

	const [finantialRef, finantialVisible] = useOnScreen({ threshold: 0.1 });
	const [anim, setAnim] = React.useState({ opacity: 0, y: 20 });

	React.useEffect(() => {
		if (finantialVisible) {
			setAnim({ opacity: 1, y: 0 });
		}
	}, [finantialVisible]);

	return (
		<section className={`container-fluid ${styles.section}`}>
			<div className="row">
				<div className="col-md-6 m-auto text-center">
					<LazyMotion features={domAnimation}>

					<m.h2
						ref={finantialRef}
						animate={anim}
						transition={{
								type: "spring",
								stiffness: 20,
							}}
							className={styles.section_title}
							>
						{t("FINANCIAL_INDUSTRY.TITLE")}
					</m.h2>
					</LazyMotion>
				</div>
			</div>
			{breakpoints.sm ? (
				<ServicesTabsMobile fromHome />
			) : (
				<Tabs>
					<TabItem name={t("FINANCIAL_INDUSTRY.SUITE.TAB_NAME")} key="1">
						<LazyMotion features={domAnimation}>

						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-4"
						>
							<h3 className={styles.displayMobile}>
								{t("FINANCIAL_INDUSTRY.SUITE.TITLE")}
							</h3>
							<p className="lh28 gray-6 mb40">
								{t("FINANCIAL_INDUSTRY.SUITE.SUBTITLE")}
							</p>
							<p className={styles.displayMobile}>
								{`"${t("FINANCIAL_INDUSTRY.SUITE.QUOTE")}"`}
							</p>
							<div className={styles.displayMobile}>
								<Author
									img={MaxHarari}
									name={t("TESTIMONIES.MAX.NAME")}
									job={t("TESTIMONIES.MAX.ROLE")}
									loading="lazy"
									/>
							</div>
						</m.div>
						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-8 text-center"
							style={{
								maxWidth: "650px",
								textAlign: "right",
								marginLeft: "auto",
							}}
							>
							<video
								className="w-100 chatVideo"
								width={600}
								height={400}
								autoPlay
								loop
								muted
								playsInline
							>
								 <source src={handleDesktopVideo(language)} type="video/mp4" />
							</video>
						</m.div>
						</LazyMotion>
					</TabItem>

					<TabItem name={t("FINANCIAL_INDUSTRY.APPS.TAB_NAME")} key="2">
						<LazyMotion features={domAnimation}>

						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-4"
						>
							<h3>{t("FINANCIAL_INDUSTRY.APPS.TITLE")}</h3>
							<p className="lh28 gray-6 mb18">
								{t("FINANCIAL_INDUSTRY.APPS.SUBTITLE")}
							</p>
							<div style={{ display: "flex" }}>
								<MessageIcon style={{ marginRight: 10 }} />
								<p className="lh28">
									<button
										style={{
											color: "inherit",
											background: "none",
											fontSize: "inherit",
											fontFamily: "inherit",
											textAlign: "left",
											cursor: "pointer",
										}}
										onClick={openChat}
									>
										{t("FINANCIAL_INDUSTRY.APPS.LINK")}
									</button>
								</p>
							</div>
						</m.div>
						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-8 text-center m-auto relative"
							style={{ height: 436 }}
						>
							<m.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ duration: 0.3 }}
								variants={imageVariants}
								whileHover="hover"
							>
								<m.div
									variants={grayCard}
									style={{
										position: "absolute",
										width: 141,
										height: 108,
										borderRadius: 11,
										background: "rgba(255, 255, 255, 0.05)",
										backdropFilter: "blur(10px)",
										top: 30,
										left: "35%",
										transform: "translateX(-50%)",
										zIndex: 3,
										display: "flex",
										flexDirection: "column",
										padding: 19,
										justifyContent: "center",
									}}
								>
									<div
										style={{
											background: "rgba(255, 255, 255, 0.2)",
											opacity: 0.5,
											backdropFilter: "blur(20px)",
											width: 80,
											height: 16,
											borderRadius: 20,
										}}
										/>
									<div
										style={{
											background: "rgba(255, 255, 255, 0.2)",
											opacity: 0.5,
											backdropFilter: "blur(20px)",
											height: 16,
											width: 55,
											marginTop: 12,
											borderRadius: 20,
										}}
										/>
								</m.div>

								<m.div
									variants={middleCard}
									style={{
										position: "absolute",
										top: "60%",
										left: "60%",
										transform: "translate(-50%, -50%)",
										borderRadius: 11,
										background: "rgba(0, 0, 0, 0.3)",
										backdropFilter: "blur(10px)",
										width: 220,
										height: 189,
										zIndex: 1,
									}}
									/>

								<m.img
									variants={pablo}
									src={Pablo}
									alt="Pablo"
									loading="lazy"
									style={{
										position: "absolute",
										top: 0,
										left: "55%",
										transform: "translateX(-50%)",
										zIndex: 2,
										width: 207,
										height: 187,
									}}
								/>
								<m.img
									variants={bottomCard}
									alt="Maria y Tebi"
									src={Tebi}
									loading="lazy"
									style={{
										position: "absolute",
										bottom: 0,
										left: "50%",
										transform: "translateX(-50%)",
										zIndex: 2,
										width: 324,
										height: 232,
										borderRadius: 4,
										resize: "both",
									}}
								/>
								<m.img
									variants={rightCard}
									alt="Iñigo"
									src={Inigo}
									loading="lazy"
									style={{
										position: "absolute",
										top: "50%",
										right: 0,
										transform: "translateY(-50%)",
										width: 217,
										height: 298,
									}}
									/>
							</m.div>
						</m.div>
						</LazyMotion>
					</TabItem>

					<TabItem name={t("FINANCIAL_INDUSTRY.ALFA.TAB_NAME")} key="3">
						<LazyMotion features={domAnimation}>

						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-4"
							>
							<h3>{t("FINANCIAL_INDUSTRY.ALFA.TITLE")}</h3>
							<p className="lh28 gray-6 mb18">
								{t("FINANCIAL_INDUSTRY.ALFA.SUBTITLE")}
							</p>
							<div style={{ display: "flex" }}>
								<PlatformIcon style={{ marginRight: 10, width: 20 }} />
								<Link to="/platform" style={{ color: "white" }}>
									<p className="lh28">{t("FINANCIAL_INDUSTRY.ALFA.LINK")}</p>
								</Link>
							</div>
						</m.div>
						<m.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							className="col-md-8 text-center"
							style={{
								maxWidth: "650px",
								textAlign: "right",
								marginLeft: "auto",
							}}
							>
							<CalendarLottie />
						</m.div>
							</LazyMotion>
					</TabItem>
				</Tabs>
			)}
			<DividerCenter />
		</section>
	);
};

function PlatformIcon(props) {
	return (
		<svg
			width={27}
			height={29}
			viewBox="0 0 27 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.027 15.805H2.634v-9.22h18.393l3.073 4.61-3.073 4.61zm5.752-5.34l-3.951-5.928a1.327 1.327 0 00-1.096-.586H13.5V1.317A1.32 1.32 0 0012.183 0a1.32 1.32 0 00-1.317 1.317v2.634H1.317A1.32 1.32 0 000 5.268v11.854c0 .73.59 1.317 1.317 1.317h9.549v7.537h-1.79a1.32 1.32 0 00-1.318 1.317c0 .73.59 1.317 1.317 1.317h6.216c.727 0 1.317-.586 1.317-1.317a1.32 1.32 0 00-1.317-1.317H13.5v-7.537h8.232c.44 0 .851-.217 1.096-.586l3.95-5.927a1.318 1.318 0 000-1.462z"
				fill="#fff"
			/>
		</svg>
	);
}

function MessageIcon(props) {
	return (
		<svg
			width={28}
			height={24}
			viewBox="0 0 28 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.066 20.226l-2.604-2.39a1.114 1.114 0 00-.763-.303H7.509v-2.326h13.466c.627 0 1.14-.517 1.14-1.163V6.978h2.95v13.248zM4.881 13.183l-2.603 2.39V2.326h17.557v10.555H5.646a1.128 1.128 0 00-.764.302zm21.323-8.53h-4.09v-3.49c0-.645-.513-1.163-1.14-1.163H1.14C.513 0 0 .518 0 1.163v17.022c0 .46.262.878.678 1.064a1.13 1.13 0 001.225-.198l3.327-3.059v2.704c0 .646.512 1.163 1.139 1.163H21.26l4.181 3.844a1.13 1.13 0 001.225.198 1.16 1.16 0 00.678-1.064V5.815c0-.645-.513-1.163-1.14-1.163z"
				fill="#fff"
			/>
		</svg>
	);
}

export default FinancialIndustry;
