import React, { useEffect} from 'react'
import Lottie from 'react-lottie';
// import animationData from './data.json'
import { useOnScreen } from '../../../hooks/useOnScreen';
import { useFetchJson } from "../../../hooks/useFetchJson";

export const CalendarLottie = () => {

  const [ref, isVisible] = useOnScreen();
  const [data, fetchData] = useFetchJson("/json/calendar/data.json");
  
  const defaultOptions = {
      loop: true,
      autoplay: true, 
      // animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };

  useEffect(() => {
    if(isVisible && data === null) fetchData();
  }, [isVisible,data,fetchData]);
  
  return (
    <div ref={ref} style={{ minHeight: 1 }}>
      {isVisible && data !== null && <Lottie options={{ ...defaultOptions, animationData: data }} height="100%" width="100%" />}
    </div>
  )
}

export default CalendarLottie;
// export default class CalendarLottie extends React.Component {
 
//   constructor(props) {
//     super(props);
//     this.state = {isStopped: false, isPaused: false};
//   }
 
//   render() {

//     const defaultOptions = {
//       loop: true,
//       autoplay: true, 
//       animationData: animationData,
//       rendererSettings: {
//         preserveAspectRatio: 'xMidYMid slice'
//       }
//     };
 
//     return <div>
//       <Lottie options={defaultOptions}
//               height="100%"
//               width="100%"
//               />
//     </div>
//   }
// }